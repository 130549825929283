import {
	required,
	min,
	max
} from "vuelidate/lib/validators";

export default {
	name: "admin-formulario-assunto",
	data() {
		return {
			blLoading: true,
			blDialogExcluir: false,
			blExcluindo: false,
			blErro: false,
			blSalvando: false,
			obForm: {
				id: 0,
				nome: "",
				ordem: "",
				fixo: false,
				link: ""
			}
		};
	},
	validations: {
		obForm: {
			nome: {
				required
			},
			ordem: {
				required
			}
		}
	},
	created() {
		if (this.$route.params.id) {
			this.obter(this.$route.params.id);
		} else {
			this.blLoading = false;
		}
	},
	methods: {
		obter(id) {
			this.$root.$api.get("assunto/obter/" + id).then(response => {
				this.$root.$utils.preencheFormularios(
					this.obForm,
					response.retorno
				);

				this.blLoading = false;
			});
		},
		mensagemErro(strCampo) {
			return this.$utils.handleErrors(strCampo, this);
		},
		validarFormulario() {
			this.blErro = false;

			this.$v.obForm.$touch();

			if (this.$v.obForm.$invalid) {
				this.blErro = true;
			}
		},
		submit() {
			this.validarFormulario();

			if (this.blErro) {
				return false;
			}

			this.blSalvando = true;

			this.$root.$api
				.post("assunto/salvar", this.obForm)
				.then(response => {
					this.blSalvando = false;

					this.$router.push({
						name: "admin-assunto"
					});
				});
		},
		excluir() {
			this.blExcluindo = true;

			this.$root.$api
				.delete("assunto/excluir/" + this.obForm.id)
				.then(response => {
					this.blDialogExcluir = false;

					this.$router.push({
						name: "admin-assunto"
					});
				});
		}
	}
};